var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _c("import-template", {
        attrs: {
          uploadUrl: _vm.uploadUrl,
          uploadLabel: "导入通用内容",
          downloadUrl: _vm.downloadUrl,
          autoUpload: true,
          downloadText: "下载通用内容关联模板",
          submitUrl: _vm.submitUrl,
        },
        on: { onSuccess: _vm.onSuccess },
        scopedSlots: _vm._u([
          {
            key: "remark",
            fn: function () {
              return [
                _vm._v("\n      最大支持10MB的excel文件，超过请拆分导入\n    "),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }